import React from 'react';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Wrapper = styled.section`
  margin: 80px auto 90px auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: justify;
  height: 430px;
  background-color: ${colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    height: auto;
    flex-direction: column;
    margin: 0;
    padding: 60px 15px;
  }
`;

const Column = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Number = styled.p`
  font-family: Libre Baskerville;
  font-size: 245px;
  line-height: 1;
  color: ${colors.navy};
  margin: 0;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 126px;
  }
`;

const Name = styled.p`
  font-family: Libre Baskerville;
  font-size: 53px;
  line-height: 1;
  letter-spacing: 3.5px;
  color: ${colors.green};
  margin: 0;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 30px;
    margin-bottom: 35px;
  }
`;

const RightColumn = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  line-height: 1;
  letter-spacing: 2.5px;
  color: ${colors.navy};
  margin: 0 0 30px 0;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${colors.black};
  margin: 0 0 30px 0;
  max-width: 565px;

  &:last-child {
    margin: 0;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const FromEveryWhere = () => (
  <Wrapper>
    <Column>
      <Number>40</Number>
      <Name>Tillistes</Name>
    </Column>
    <RightColumn>
      <Title>De tous les horizons</Title>
      <Text>Ils viennent de France, du Maroc, du Vénézuela, du Brésil, de Hongrie, de Sierra Leone, d’Espagne, de Corée ou encore du Cambodge...</Text>
      <Text>Certains ont fait de grandes écoles de mode, d’autres ont appris le métier
        auprès de créateurs de renom, ou aux côtés d’une grand mère, d’une tante qui avait
        le talent des grands couturiers. Certains ont appris seul, par passion,
        avec l’envie de réaliser un rêve. D’autres ont décidé du jour au lendemain
        de devenir couturier car c’était finalement ce qui les faisait vibrer.
        Certains ont eu 1 000 vies avant Tilli, et d’autres travaillent pour la 1ère fois.</Text>
    </RightColumn>
  </Wrapper>
);

export default FromEveryWhere;
