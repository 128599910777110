import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Wrapper = styled.header`
  max-width: 1440px;
  text-align: center;
  margin: 80px auto 90px auto;
  padding: 0 80px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 60px;
    margin-top: 60px;
    padding: 0 15px;
  }
`;

const Title = styled.h1`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 32px;
  line-height: 14px;
  letter-spacing: 5.5px;
  color: ${colors.navy};
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 23px;
    line-height: 32px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${colors.black};
  margin: 40px auto;
  max-width: 596px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 25px;
    margin-top: 25px;
  }
`;

const TopBar = ({ data: { title, text } }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Text>{text}</Text>
  </Wrapper>
);

TopBar.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

export default TopBar;
