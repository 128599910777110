import React from 'react';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Wrapper = styled.section`
  max-width: 1440px;
  margin: 80px auto 90px auto;
  padding: 0 57px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: justify;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 60px;
    margin-top: 60px;
    padding: 0 15px;
    flex-direction: column-reverse;
  }
`;

const Column = styled.div`
  flex-basis: 50%;
`;

const ColumnWrapper = styled.div`
  width: 570px;
  max-width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  line-height: 1;
  letter-spacing: 2.5px;
  color: ${colors.navy};
  margin: 0 0 20px 0;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 25px;
  color: ${colors.black};
  margin: 0 0 20px 0;
  text-align: justify;

  &:last-child {
    margin: 0;
  }

  strong {
    font-weight: 500;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const RightColumn = styled.div`
  flex-basis: 50%;
  display: flex;
  justify-content: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 50px;
  }
`;

const Blockquote = styled.blockquote`
  width: 532px;
  max-width: 100%;
  font-family: Libre Baskerville;
  font-size: 40px;
  line-height: 53px;
  color: ${colors.navy};
  position: relative;
  z-index: 0;

  &:before,
  &:after {
    position: absolute;
    font-family: Libre Baskerville;
    font-weight: bold;
    font-size: 254px;
    line-height: 53px;
    color: ${colors.greenShadow};
    z-index: -1;
  }

  &:before {
    content: '“';
    top: 50px;
    left: -72px;
  }

  &:after {
    content: '”';
    bottom: -95px;
    right: 5px;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    font-size: 20px;
    line-height: 39px;

    &:before,
    &:after {
      font-size: 165px;
    }

    &:before {
      content: '“';
      top: 25px;
      left: -45px;
    }

    &:after {
      content: '”';
      bottom: -56px;
      right: -15px;
    }
  }
`;

const BigFamily = () => (
  <Wrapper>
    <Column>
      <ColumnWrapper>
        <Title>Une grande famille de couturiers</Title>
        <Text>
          Chez Tilli, nous avons à coeur de voir grandir et de consolider la communauté de nos Tillistes.
          C’est pourquoi nous entretenons un lien particulier avec chacun d’entre eux.
          Au delà de voir croître cette communauté, nous voulons qu’elle reste la même sur du long terme.
        </Text>
        <Text>
          <strong>Ce que nous aimons, c’est avant tout les rapports humains, le mélange des savoir-faire,
          voir nos Tillistes s’épanouir et avoir envie de continuer à apprendre, toujours plus.
          Les sentir investis, et solidaires de notre évolution.</strong>
        </Text>
        <Text>
          C’est pourquoi nous organisons des événements et des ateliers,
          pour que nos Tillistes aient des occasions de se voir et de partager,
          de monter en compétence, de transmettre leur savoir tout au long de l’année.
        </Text>
      </ColumnWrapper>
    </Column>
    <RightColumn>
      <Blockquote>
        Ce que nous créons depuis 3 ans, c’est une relation de confiance, de partage et d’entraide.
      </Blockquote>
    </RightColumn>
  </Wrapper>
);

export default BigFamily;
