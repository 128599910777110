import React from 'react';
import styled from 'styled-components';
import TextLoop from 'react-text-loop';

import { colors, mobileThresholdPixels } from '../../home/v3/styledComponents';

const Container = styled.section`
  max-width: 1440px;
  margin: 80px auto 90px auto;
  padding: 0 57px 0 156px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 60px;
    margin-top: 60px;
    padding: 0 15px;
  }
`;

const AnimatedText = styled.div`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  line-height: 27px;
  color: ${colors.navy};
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: start;
  }
`;

const TextLeft = styled.div`
  flex-basis: 50%;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;

  @media (max-width: 1220px) {
    padding-right: 0px;
    padding-bottom: 0px;
    flex-basis: 55%;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: left;
  }
`;

const TextRight = styled(TextLoop)`
  flex-basis: 50%;
  padding-bottom: 10px;

  @media (max-width: 1220px) {
    flex-basis: 45%;
  }
`;

const WrapperTwoColumns = styled.section`
  display: flex;
  align-items: center;
  text-align: justify;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex-basis: 50%;
`;

const ColumnWrapper = styled.div`
  max-width: 470px;

  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  line-height: 27px;
  letter-spacing: 2.5px;
  color: ${colors.navy};
  margin: 0 0 20px 0;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${colors.black};
  margin: 0 0 20px 0;
  text-align: justify;

  &:last-child {
    margin: 0;
  }

  strong {
    font-weight: 500;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin: 0 0 40px 0;
    }
  }
`;

const RightColumn = styled.div`
  flex-basis: 50%;
  display: flex;
  justify-content: center;

  @media (max-width: ${mobileThresholdPixels}) {
    align-self: start;
  }
`;

const RightColumnWrapper = styled.div`
  max-width: 544px;
`;

const RightColumnText = styled.p`
  font-family: Libre Baskerville;
  font-size: 60px;
  line-height: 82px;
  color: ${colors.navy};
  margin: 0;

  &:last-child {
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      bottom: 12px;
      left: 0;
      width: 220px;
      height: 15px;
      background-color: ${colors.green};
      z-index: -1;
    }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 30px;
    line-height: 35px;

    &:last-child {
      position: relative;
      z-index: 0;

      &::before {
        bottom: 0;
        width: 112px;
        height: 10px;
      }
    }
  }
`;

const SameValues = () => (
  <Container>
    <AnimatedText>
      <TextLeft>Ce qu’ils ont tous en commun c’est :</TextLeft>
      <TextRight interval={3000} springConfig={{ stiffness: 180, damping: 10 }} mask>
        <span>le talent</span>
        <span>la notion du travail bien fait</span>
        <span>l’envie</span>
        <span>le professionnalisme</span>
        <span>le sens du détail</span>
        <span>la passion de ce métier unique</span>
        <span>la bienveillance</span>
        <span>la transmission</span>
        <span>le partage</span>
        <span>la remise en question</span>
        <span>l’écoute</span>
        <span>l’humilité</span>
      </TextRight>
    </AnimatedText>
    <WrapperTwoColumns>
      <Column>
        <ColumnWrapper>
          <Title>Un lien fort avec nos artisans</Title>
          <Text>
            Chez Tilli, nous savons qu’il faut être bien entouré pour bien grandir.
            C’est pourquoi nous sélectionnons avec beaucoup de soin nos collaborateurs.
            Nous les accompagnons, les guidons à leur arrivée parmi nous.
            Nous prenons le temps “de prendre le temps” pour échanger avec notre communauté,
            car ils constituent les fondations de notre Maison.
          </Text>
          <Text>
            <strong>Chaque Tilliste compte, chaque échange est précieux et nous continuerons à garder le lien.
              Aujourd’hui 40 Tillistes... demain 500. La Maison s’agrandit, le lien reste.</strong>
          </Text>
        </ColumnWrapper>
      </Column>
      <RightColumn>
        <RightColumnWrapper>
          <RightColumnText>Ils ont</RightColumnText>
          <RightColumnText>les mêmes</RightColumnText>
          <RightColumnText>valeurs que nous</RightColumnText>
        </RightColumnWrapper>
      </RightColumn>
    </WrapperTwoColumns>
  </Container>
);

export default SameValues;
