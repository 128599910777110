import React from 'react';

import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';
import { Container, Content } from '../../components/home/homeStyledComponents';

import TopBar from '../../components/home/OurArtisans/TopBar';
import Board from '../../components/home/OurArtisans/Board';
import BigFamily from '../../components/home/OurArtisans/BigFamily';
import FromEveryWhere from '../../components/home/OurArtisans/FromEveryWhere';
import SameValues from '../../components/home/OurArtisans/SameValues';
import Bottom from '../../components/home/OurArtisans/Bottom';

const topBarData = {
  title: 'Nos artisans couturiers',
  text: `Bienvenue dans la Maison Tilli.
  Nous allons vous partager les secrets de notre communauté
  de stylistes, modélistes, couturiers, les Tillistes...`,
};

const OurArtisansPage = () => (
  <Layout routeSlug="OurArtisansPage">
    <Container>
      <Content>
        <MenuBar />
        <TopBar data={topBarData} />
        <Board />
        <BigFamily />
        <FromEveryWhere />
        <SameValues />
        <Bottom />
        <Footer />
      </Content>
    </Container>
  </Layout>
);

export default OurArtisansPage;
